import { call, cancel, fork, take } from "redux-saga/effects";

import loadList, { getApiProps } from "./loadList";

import * as blogApi from "api/live/blog";
import * as paginationConstants from "constants/pagination";
import { apiCall } from "sagas/api";

export default function* loadPostsList(action) {
  const { apiProps, pagination } = yield call(getApiProps, action);

  const response = yield call(apiCall, {
    fn: blogApi.loadBlogPosts,
    data: apiProps,
  });

  yield call(apiCall, {
    fn: loadList,
    data: {
      list_type: "posts_filtered",
      action,
      pagination,
      apiProps,
      response,
    },
  });
}

export function* loadPostsListWatch() {
  const tasksByKey = {};

  while (true) {
    const action = yield take(paginationConstants.LOAD_POSTS_LIST);
    if (tasksByKey[action.key]) {
      yield cancel(tasksByKey[action.key]);
    }
    tasksByKey[action.key] = yield fork(loadPostsList, action);
  }
}

loadPostsListWatch.displayName = "loadPostsListWatch";
